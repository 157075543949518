import { v4 } from "uuid";
import { Dispatch, SetStateAction } from "react";

import { API_CALLS } from "../../../../../../../../API/api_calls";

export const validFileTypes = [
  "image/jpeg",
  "image/png",
  "image/webp",
  "image/avif",
];

export const MAX_IMAGE_UPLOAD_COUNT = 10;

export const validFile = (files: FileList) => {
  return Array.from(files).filter(
    (file) => validFileTypes.includes(file.type) && file.size < 10000000
  );
};

export const getImageBase64 = (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

export const uploadFile = ({
  files,
  post_id,
  uploadedImages,
  setImageUploadOpen,
  setIsMoreImgUploadButtonDisable,
}: {
  files: FileList;
  uploadedImages: number;
  setImageUploadOpen: Dispatch<SetStateAction<boolean>>;
  setIsMoreImgUploadButtonDisable: Dispatch<SetStateAction<boolean>>;
  post_id: string;
}) => {
  const remainingImages = MAX_IMAGE_UPLOAD_COUNT - uploadedImages;
  const validFileList = validFile(files).slice(0, remainingImages);

  if (!validFileList.length) return;

  API_CALLS.POSTS.uploadsImageForAPost(
    {
      post_id,
      images: validFileList.map((file) => ({ file, id: v4() })),
    },
    setImageUploadOpen,
    setIsMoreImgUploadButtonDisable
  );
};
