import styled, { useTheme } from "styled-components";
import { Components } from "@thisgorilla/primate-ds";
import { useContext, useEffect, useRef, useState } from "react";

import Slider from "./Slider";
import { CreatePostModalContext } from "../..";
import { EmojiProps } from "../../../../../../../Emoji";
import { useContainerSize } from "../../../../../../../../hooks/useContainerSize";

const SliderPreview = ({
  emojiValue,
}: {
  emojiValue: EmojiProps.EmojiType[];
}) => {
  const ref = useRef(null);
  const { typography } = useTheme();
  const size = useContainerSize(ref);
  const [height, setHeight] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const { formik } = useContext(CreatePostModalContext);

  useEffect(() => {
    if (ref?.current) {
      setHeight((ref?.current as Element)?.scrollHeight ?? 0);
    }
  }, [size, isOpen, formik.values.slider_question]);

  return (
    <SliderContainer $isShow={isOpen}>
      <PreviewHeading>
        <Components.Label kind="Fill" text="Preview" color="Primary" />
        <Components.IconButton
          kind="Secondary"
          iconType={isOpen ? "ChevronUp" : "ChevronDown"}
          disabled={false}
          size="Small"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        />
      </PreviewHeading>
      <PreviewBody ref={ref} height={isOpen ? height + 6 : 0}>
        <PreviewContent>
          <Components.Text
            text={formik.values.slider_question || ""}
            style={{ tokenStyle: typography.Body.L }}
            fontWeight="font-weight-semibold"
            lineHeight="line-height-200"
          />
          <PreviewContent>
            <Slider emojiValue={emojiValue} />
          </PreviewContent>
        </PreviewContent>
      </PreviewBody>
    </SliderContainer>
  );
};

export default SliderPreview;

const SliderContainer = styled.div<{ $isShow: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme, $isShow }) =>
    $isShow
      ? theme.spacing["spacing-2"].value
      : theme.spacing["spacing-none"].value}px;
  padding: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  border-radius: ${({ theme }) => theme.radius["radius-xxs"].value}px;
  border: 1px solid
    ${({ theme }) => theme.Color.border["ui-border-primary-subtle"].value};
  background: ${({ theme }) =>
    theme.Color.background.brand.subtlest["default-hover"].value};
`;

const PreviewHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
`;

const PreviewContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  padding: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  border-radius: ${({ theme }) => theme.radius["radius-xxs"].value}px;
  border: 1px solid
    ${({ theme }) => theme.Color.border["ui-border-neutral"].value};
  background: ${({ theme }) =>
    theme.Color.elevation.surface["surface-primary"].value};
`;

const PreviewBody = styled.div<{ height: number }>`
  max-height: ${({ height }) => height + "px"};
  overflow: hidden;
  transition: max-height 0.3s ease;
`;
