import { FormikProps } from "formik";
import { MeProps } from "./redux/slices/me/Me";

export const getHexValueForPercentage = (percentage: number) => {
  const hex = Math.floor((percentage / 100) * 255).toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
};
export const addProxyUrlToProfileImage = (profile_picture: string) => {
  const isGoogleProfilePicture = profile_picture.includes(
    "googleusercontent.com"
  );

  if (!isGoogleProfilePicture) return profile_picture;
  return `https://images.weserv.nl/?url=${profile_picture}`;
};

export const calculateTimePassed = (dateString: string) => {
  if (!dateString) return "";

  const date = new Date(dateString);
  const now = new Date();
  const diff = now.getTime() - date.getTime();

  if (isNaN(diff)) return "";

  const minutes = Math.floor(diff / 60000);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);

  if (years > 0) {
    return `${years} year ago`;
  } else if (months > 0) {
    return `${months} month ago`;
  } else if (days > 0) {
    return `${days}d ago`;
  } else if (hours > 0) {
    return `${hours}h ago`;
  } else if (minutes > 0) {
    return `${minutes}m ago`;
  } else {
    return "Just now";
  }
};

export const disableScroll = () => {
  const body = document.getElementsByTagName("body")[0];
  body.style.overflow = "hidden";
};

export const enableScroll = () => {
  const body = document.getElementsByTagName("body")[0];
  body.style.overflow = "auto";
};

export const getPeopleNamesText = (
  peopleNamesData: MeProps.UserWithFollowersMetadata[] | MeProps.User[],
  maxPeopleNamesLength: number = 2
) => {
  if (!peopleNamesData || !peopleNamesData.length) return "";

  const getName = (people: MeProps.UserWithFollowersMetadata | MeProps.User) =>
    `${people.first_name} ${people.last_name}`;

  if (peopleNamesData.length <= maxPeopleNamesLength)
    return peopleNamesData.map(getName).join(" and ");

  const peopleNames = peopleNamesData
    .slice(0, maxPeopleNamesLength)
    .map(getName);
  const additionalCount = `and +${
    peopleNamesData.length - maxPeopleNamesLength
  } others`;
  const peopleNamesText = `${peopleNames.join(", ")} ${additionalCount}`;
  return peopleNamesText;
};

export const isFormSubmitButtonDisabled = ({
  formik,
  requiredFileds,
}: {
  formik: FormikProps<any>;
  requiredFileds: { [key: string]: boolean };
}) => {
  const { values, isValid, errors } = formik;
  if (!isValid || Object.keys(errors).length) {
    return true;
  }

  for (const field of Object.keys(requiredFileds)) {
    if (!values[field]) {
      return requiredFileds[field];
    }
  }
  return false;
};
