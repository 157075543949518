import styled, { css } from "styled-components";
import { EmojiProps } from "../../../../../../../../Emoji";
import { Emoji } from "../../../../../../../../Emoji/EmojiIcon";

const Slider = ({ emojiValue }: { emojiValue: EmojiProps.EmojiType[] }) => {
  return (
    <SliderContainer>
      <Emoji type={emojiValue[0]} />
      <CustomSlider type="range" min="1" max="100" value="50" />
      <Emoji type={emojiValue[1]} />
    </SliderContainer>
  );
};

export default Slider;

const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 51px;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
`;

const sliderThumbCss = css`
  width: 51px;
  height: 51px;
  border-radius: 50%;
  background: ${({ theme }) =>
    theme.Color.elevation.surface["surface-primary"].value};
  border: 8px solid
    ${({ theme }) => theme.Color.border["ui-border-emphasize-brand"].value};
  cursor: pointer;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
`;

const CustomSlider = styled.input`
  margin: ${({ theme }) => theme.spacing["spacing-none"].value}px;
  width: 100%;
  -webkit-appearance: none;
  max-width: 349px;
  height: 16px;
  border-radius: 60px;
  background: linear-gradient(90deg, #e5e8eb 0%, #6c777f 49.97%, #e5e8eb 100%);
  cursor: pointer;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    ${sliderThumbCss}
  }

  &::-moz-range-thumb {
    ${sliderThumbCss}
  }
`;
