import styled from "styled-components";
import { Components, Inputs } from "@thisgorilla/primate-ds";

import { DefaultPostProps } from "./DefaultPost";
import { useAppSelector } from "../../../../../redux";

export const AvatarAndText = ({
  openCreatePostModal,
}: DefaultPostProps.OpenPostModal) => {
  const { post_theme, profile_picture } = useAppSelector((state) => state.me);
  return (
    <Container>
      <Components.Avatar
        type="Circle"
        size="Medium-48"
        src={profile_picture}
        disabled={false}
      />
      <div
        style={{ width: "100%" }}
        onClick={(e) => {
          e.preventDefault();
          openCreatePostModal();
        }}
        onMouseDown={(e) => e.preventDefault()}
      >
        <Inputs.Input
          idPrefix={"default-post"}
          fieldName="post"
          isRequired={false}
          onChangeHandler={() => {}}
          onFocus={() => {}}
          value=""
          placeholder={post_theme}
          size="Large"
        />
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
