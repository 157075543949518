import { useContext } from "react";
import { Components } from "@thisgorilla/primate-ds";
import styled, { useTheme } from "styled-components";

import { CreatePostModalContext } from "../..";
import { ImageUploadProps } from "./ImageUpload";
import { useAppSelector } from "../../../../../../../../redux";

const MediaUpload = ({ fileInputRef }: ImageUploadProps.IProps) => {
  const { typography } = useTheme();
  const { uploadedFiles } = useAppSelector((state) => state.posts);
  const { setImageUploadOpen } = useContext(CreatePostModalContext);
  const addmore = uploadedFiles.length === 1;

  return (
    <MediaUploadContainer onClick={() => fileInputRef?.current?.click()}>
      <Container $addmore={addmore}>
        <Components.IconButton
          kind="Primary"
          size={addmore ? "Small" : "Large"}
          disabled={false}
          iconType="Image"
          onClick={() => {}}
        />
        <Components.Text
          style={{ tokenStyle: typography.Heading[addmore ? "XS" : "S"] }}
          text="Add images"
          fontWeight="font-weight-semibold"
          lineHeight={addmore ? "line-height-75" : "line-height-400"}
        />
        <Components.Text
          style={{ tokenStyle: typography.Body[addmore ? "XS" : "S"] }}
          text="Or drag and drop here"
          fontWeight="font-weight-semibold"
          lineHeight={addmore ? "line-height-25" : "line-height-50"}
        />
        <ButtonContainer>
          <Components.IconButton
            disabled={false}
            iconType="X"
            kind="Secondary"
            onClick={(e) => {
              e.stopPropagation();
              setImageUploadOpen(false);
            }}
            size="Small"
          />
        </ButtonContainer>
      </Container>
    </MediaUploadContainer>
  );
};

export default MediaUpload;

const MediaUploadContainer = styled.div`
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  border-radius: ${({ theme }) => theme.radius["radius-m"].value}px;
  box-shadow: ${({ theme }) => theme.boxShadows["box-shadow-s"]};
  border: 1px solid
    ${({ theme }) => theme.Color.border["ui-border-neutral"].value};
  background: ${({ theme }) =>
    theme.Color.elevation.surface["surface-secondary"].value};

  &:hover {
    & {
      border-color: ${({ theme }) =>
        theme.Color.border["ui-border-emphasize-brand"].value};
      background: ${({ theme }) =>
        theme.Color.background.brand.subtlest["default-hover"].value};
    }
    & > div {
      & > button {
        background: ${({ theme }) =>
          theme.Color.background.brand.default.value};
      }
      & > label:nth-child(2) {
        color: ${({ theme }) =>
          theme.Color.text["text-emphasize-brand-bolder"].value};
      }
    }
  }
`;

const Container = styled.div<{ $addmore: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing["spacing-1"].value}px;
  padding: ${({ theme, $addmore }) =>
      $addmore ? theme.spacing["spacing-6"].value : 47}px
    ${({ theme }) => theme.spacing["spacing-4"].value}px
    ${({ theme, $addmore }) =>
      $addmore ? theme.spacing["spacing-2"].value : 47}px;
  border-radius: ${({ theme }) => theme.radius["radius-xs"].value}px;
  border: 1px dashed
    ${({ theme }) => theme.Color.border["ui-border-emphasize-brand"].value};
  background: ${({ theme }) =>
    theme.Color.elevation.surface["surface-primary-emphasize-hover"].value};

  & > button {
    background: ${({ theme }) =>
      theme.Color.background.brand.subtle.default.value};
  }
`;

const ButtonContainer = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  right: ${({ theme }) => theme.spacing["spacing-2"].value}px;
`;
