import { EmojiProps } from "./Emoji";

export const Smile = ({ size = 24 }: EmojiProps.Emoji) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 23 23"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.3136 11.3136C22.3169 16.0413 19.2486 20.2231 14.7377 21.6386C10.2268 23.0541 5.31936 21.3751 2.62093 17.493C-0.0774969 13.611 0.058768 8.4261 2.95738 4.69118C2.93494 4.53808 2.92373 4.38354 2.92382 4.22881C2.92382 2.37538 4.42632 0.872877 6.27975 0.872877C6.76983 0.871177 7.25405 0.979488 7.6967 1.18983H7.70043C10.15 0.270058 12.8501 0.270058 15.2998 1.18983H15.3035C15.7461 0.979488 16.2303 0.871177 16.7204 0.872877C18.5739 0.872877 20.0764 2.37538 20.0764 4.22881C20.0765 4.38354 20.0652 4.53808 20.0428 4.69118C21.5184 6.58292 22.3179 8.91438 22.3136 11.3136Z"
        fill="#F0C419"
      />
      <path
        d="M15.6015 12.805C17.455 12.805 18.9575 10.9686 18.9575 8.70326C18.9575 6.43795 17.455 4.60156 15.6015 4.60156C13.7481 4.60156 12.2456 6.43795 12.2456 8.70326C12.2456 10.9686 13.7481 12.805 15.6015 12.805Z"
        fill="#ECF0F1"
      />
      <path
        d="M7.39841 12.805C9.25184 12.805 10.7543 10.9686 10.7543 8.70326C10.7543 6.43795 9.25184 4.60156 7.39841 4.60156C5.54498 4.60156 4.04248 6.43795 4.04248 8.70326C4.04248 10.9686 5.54498 12.805 7.39841 12.805Z"
        fill="#ECF0F1"
      />
      <path
        d="M7.77147 11.6863C9.00709 11.6863 10.0088 10.3508 10.0088 8.70327C10.0088 7.05577 9.00709 5.72021 7.77147 5.72021C6.53585 5.72021 5.53418 7.05577 5.53418 8.70327C5.53418 10.3508 6.53585 11.6863 7.77147 11.6863Z"
        fill="#2FA8CC"
      />
      <path
        d="M15.229 11.6863C16.4646 11.6863 17.4663 10.3508 17.4663 8.70327C17.4663 7.05577 16.4646 5.72021 15.229 5.72021C13.9934 5.72021 12.9917 7.05577 12.9917 8.70327C12.9917 10.3508 13.9934 11.6863 15.229 11.6863Z"
        fill="#2FA8CC"
      />
      <path
        d="M7.77131 10.5677C8.59506 10.5677 9.26284 9.73296 9.26284 8.70327C9.26284 7.67359 8.59506 6.83887 7.77131 6.83887C6.94756 6.83887 6.27979 7.67359 6.27979 8.70327C6.27979 9.73296 6.94756 10.5677 7.77131 10.5677Z"
        fill="#35495E"
      />
      <path
        d="M15.2288 10.5677C16.0526 10.5677 16.7204 9.73296 16.7204 8.70327C16.7204 7.67359 16.0526 6.83887 15.2288 6.83887C14.4051 6.83887 13.7373 7.67359 13.7373 8.70327C13.7373 9.73296 14.4051 10.5677 15.2288 10.5677Z"
        fill="#35495E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.41545 4.60165C4.29559 4.60168 4.18303 4.54409 4.11292 4.44687C4.04281 4.34965 4.02371 4.22466 4.06159 4.11094C4.24615 3.43789 4.7122 2.87723 5.3402 2.5728C6.05209 2.30412 6.83908 2.31358 7.5443 2.59928C7.73397 2.67959 7.82262 2.89846 7.7423 3.08813C7.66199 3.27779 7.44312 3.36644 7.25345 3.28613C6.73818 3.08087 6.16593 3.0696 5.64298 3.25443C5.20945 3.47738 4.89163 3.87469 4.76932 4.3466C4.71858 4.49894 4.57602 4.60169 4.41545 4.60165Z"
        fill="#E57E25"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5847 4.60149C18.4241 4.60152 18.2815 4.49877 18.2308 4.34643C18.1085 3.87453 17.7907 3.47722 17.3571 3.25427C16.834 3.06972 16.2617 3.08098 15.7463 3.28596C15.557 3.36446 15.3398 3.2755 15.26 3.08676C15.1802 2.89803 15.2676 2.68025 15.4558 2.59911C16.1611 2.31363 16.948 2.30418 17.6599 2.57264C18.2879 2.87707 18.754 3.43772 18.9385 4.11077C18.9764 4.22449 18.9573 4.34949 18.8872 4.4467C18.8171 4.54392 18.7045 4.60151 18.5847 4.60149Z"
        fill="#E57E25"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4999 20.6358C17.9008 20.6358 19.0694 15.902 19.2827 14.1733C19.2987 14.0433 19.2453 13.9143 19.1419 13.8337C19.0386 13.7531 18.9005 13.7327 18.7782 13.78C16.4465 14.6301 13.9818 15.0577 11.4999 15.0425C9.01809 15.0577 6.55342 14.6301 4.22167 13.78C4.09941 13.7327 3.96132 13.7531 3.85795 13.8337C3.75459 13.9143 3.70114 14.0433 3.71716 14.1733C3.93045 15.902 5.09906 20.6358 11.4999 20.6358Z"
        fill="#802F34"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.55071 13.9235C2.43085 13.9235 2.31828 13.8659 2.24817 13.7687C2.17807 13.6715 2.15897 13.5465 2.19684 13.4328C2.46046 12.6313 3.19885 12.0817 4.04223 12.0591C4.24817 12.0585 4.41562 12.2249 4.41623 12.4308C4.41685 12.6368 4.25041 12.8042 4.04447 12.8048C3.52166 12.827 3.06744 13.1712 2.90457 13.6684C2.85383 13.8208 2.71127 13.9235 2.55071 13.9235Z"
        fill="#F29C1F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8223 13.9235C20.6617 13.9235 20.5191 13.8208 20.4684 13.6684C20.3056 13.171 19.8511 12.8268 19.3281 12.8048C19.1222 12.8041 18.9558 12.6366 18.9565 12.4307C18.9573 12.2247 19.1248 12.0584 19.3307 12.0591C20.1741 12.0817 20.9125 12.6313 21.1761 13.4328C21.214 13.5465 21.1949 13.6715 21.1248 13.7687C21.0547 13.8659 20.9421 13.9235 20.8223 13.9235Z"
        fill="#F29C1F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9224 19.5916C14.5672 20.3263 13.0407 20.6866 11.5 20.6357C9.95932 20.6866 8.43286 20.3263 7.07764 19.5916C7.77169 18.6581 8.84668 18.0837 10.0085 18.0255C10.6054 17.9785 11.1794 18.2655 11.5 18.7713C11.8206 18.2655 12.3946 17.9785 12.9915 18.0255C14.1533 18.0837 15.2283 18.6581 15.9224 19.5916Z"
        fill="#FF5364"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9777 14.3491L16.8472 15.2776C16.7621 15.8787 16.3212 16.3683 15.7323 16.5156C14.3416 16.8104 12.9213 16.9418 11.5001 16.9071C10.0789 16.9418 8.65857 16.8104 7.26788 16.5156C6.67894 16.3683 6.23803 15.8787 6.15297 15.2776L6.02246 14.3491C7.81158 14.8115 9.6522 15.0445 11.5001 15.0427C13.348 15.0445 15.1886 14.8115 16.9777 14.3491Z"
        fill="#ECF0F1"
      />
      <path
        d="M15.9748 7.95783C16.3866 7.95783 16.7205 7.62394 16.7205 7.21207C16.7205 6.8002 16.3866 6.46631 15.9748 6.46631C15.5629 6.46631 15.229 6.8002 15.229 7.21207C15.229 7.62394 15.5629 7.95783 15.9748 7.95783Z"
        fill="#ECF0F1"
      />
      <path
        d="M8.51725 7.95783C8.92912 7.95783 9.26301 7.62394 9.26301 7.21207C9.26301 6.8002 8.92912 6.46631 8.51725 6.46631C8.10537 6.46631 7.77148 6.8002 7.77148 7.21207C7.77148 7.62394 8.10537 7.95783 8.51725 7.95783Z"
        fill="#ECF0F1"
      />
    </svg>
  );
};
