import { useContext } from "react";
import styled from "styled-components";
import { Components } from "@thisgorilla/primate-ds";

import PostAs from "./PostAs";
import PostTo from "./PostTo";
import { CreatePostModalContext } from "..";
import { Divider } from "../../../../../../../commonStyles";

const ModalHeader = () => {
  const { resetPostState } = useContext(CreatePostModalContext);

  return (
    <HeaderContainer>
      <PostAsAndToContainer>
        <PostAs />
        <Divider />
        <PostTo />
      </PostAsAndToContainer>
      <Components.IconButton
        disabled={false}
        iconType="X"
        kind="Tertiary"
        onClick={resetPostState}
        size="Medium"
      />
    </HeaderContainer>
  );
};

export default ModalHeader;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing["spacing-4"].value}px;
  padding: ${({ theme }) => theme.spacing["spacing-4"].value}px
    ${({ theme }) => theme.spacing["spacing-4"].value}px
    ${({ theme }) => theme.spacing["spacing-2"].value}px;
`;

const PostAsAndToContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 280px;
  width: 100%;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
`;
