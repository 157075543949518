import { useContext, useMemo } from "react";
import styled from "styled-components";
import { Components } from "@thisgorilla/primate-ds";

import { CreatePostModalContext } from "..";
import { CreatePostModalProps } from "../CreatePostModal";
import { useAppSelector } from "../../../../../../../redux";
import { Divider } from "../../../../../../../commonStyles";
import { isFormSubmitButtonDisabled } from "../../../../../../../utils";
import { MAX_IMAGE_UPLOAD_COUNT } from "../ModalContent/ImageUpload/utils";

const ModalFooter = () => {
  const { uploadedFiles } = useAppSelector((state) => state.posts);
  const {
    setPollOpen,
    formik,
    pollOpen,
    choiceCount,
    setImageUploadOpen,
    setSliderOpen,
    sliderOpen,
    isMoreImgUploadButtonDisable,
  } = useContext(CreatePostModalContext);

  const footerLeftSideOptions: CreatePostModalProps.FooterLeftSideOption[] = [
    {
      iconType: "Image",
      onClick: () => {
        setImageUploadOpen(true);
      },
      tooltipLabel: "Add media",
      disabled:
        isMoreImgUploadButtonDisable ||
        uploadedFiles.length === MAX_IMAGE_UPLOAD_COUNT,
    },
    { iconType: "Video", onClick: () => {}, tooltipLabel: "Add videos" },
    { iconType: "Gif", onClick: () => {}, tooltipLabel: "Add a GIF" },
    { iconType: "Smile", onClick: () => {}, tooltipLabel: "Add emoji" },
    {
      iconType: "Poll",
      onClick: () => {
        setPollOpen(true);
      },
      tooltipLabel: "Create a poll",
    },
    {
      iconType: "Slider",
      onClick: () => setSliderOpen(true),
      tooltipLabel: "Create a slider",
    },
    {
      iconType: "FileText",
      onClick: () => {},
      tooltipLabel: "Write an article",
    },
  ];

  const requiredFileds = useMemo(() => {
    const required: { [key: string]: boolean } = { content: true };

    if (pollOpen) {
      required["question"] = true;
      required["duration"] = true;
      for (let i = 1; i <= choiceCount.length; i++) {
        required[`choice_${choiceCount[i - 1]}`] = true;
      }
    }

    if (sliderOpen) {
      required["slider_question"] = true;
      required["left_emoji_id"] = true;
      required["right_emoji_id"] = true;
    }

    return required;
  }, [choiceCount, pollOpen, sliderOpen]);

  return (
    <Container>
      <Divider />
      <ModalFooterContainer>
        <FooterLeftSide>
          {footerLeftSideOptions.map(
            ({ iconType, onClick, tooltipLabel, disabled = false }, index) => (
              <Components.Tooltip
                label={tooltipLabel}
                position="top"
                key={index}
              >
                <Components.IconButton
                  iconType={iconType}
                  onClick={onClick}
                  disabled={disabled}
                  kind="Secondary"
                  size="Medium"
                />
              </Components.Tooltip>
            )
          )}
        </FooterLeftSide>
        <FooterRightSide>
          <Components.Button
            color="Neutral"
            kind="Secondary"
            disabled={false}
            label="Topics"
            onClick={() => {}}
            size="Medium"
            iconPosition="left"
            iconType="PlusCircle"
          />
          <Components.Tooltip label="Schedule post" position="top">
            <Components.IconButton
              iconType="Calendar"
              onClick={() => {}}
              disabled={false}
              kind="Secondary"
              size="Medium"
            />
          </Components.Tooltip>
          <Components.Button
            color="Primary"
            kind="Primary"
            label="Post"
            onClick={() => {
              formik.isValid && formik.submitForm();
            }}
            disabled={isFormSubmitButtonDisabled({
              formik,
              requiredFileds: requiredFileds,
            })}
            size="Medium"
          />
        </FooterRightSide>
      </ModalFooterContainer>
    </Container>
  );
};

export default ModalFooter;

const Container = styled.div`
  padding: ${({ theme }) => theme.spacing["spacing-2"].value}px
    ${({ theme }) => theme.spacing["spacing-4"].value}px
    ${({ theme }) => theme.spacing["spacing-4"].value}px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing["spacing-4"].value}px;
`;

const ModalFooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing["spacing-4"].value}px;
`;

const FooterLeftSide = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
`;
const FooterRightSide = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing["spacing-4"].value}px;
`;
