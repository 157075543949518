import styled from "styled-components";
import { Field, FieldProps } from "formik";
import { useContext, useState } from "react";
import { Inputs } from "@thisgorilla/primate-ds";

import CreatePoll from "./CreatePoll";
import UrlPreview from "./UrlPreview";
import { findCustomURL } from "./utils";
import ImageUpload from "./ImageUpload";
import CreateSlider from "./CreateSlider";
import { CreatePostModalContext } from "..";
import { RICHTEXT_INITIAL_VALUE } from "../utils";
import { useAppSelector } from "../../../../../../../redux";
import instance from "../../../../../../../axios/axios-user";
import { hideScrollBar } from "../../../../../../../commonStyles";

const ModalContent = () => {
  const [isPreviewShow, setIsPreviewShow] = useState(true);
  const { post_theme } = useAppSelector((state) => state.me);
  const { pollOpen, setUrlData, urlData, sliderOpen } = useContext(
    CreatePostModalContext
  );
  const fieldNameState = "editorState";
  const fieldNameContent = "content";

  return (
    <ModalContentContainer id="create-post-modal-content">
      <Field key={fieldNameState} name={fieldNameState}>
        {({ field, form }: FieldProps) => {
          const { value } = field;
          const { setFieldValue, validateField } = form;

          return (
            <Inputs.RichTextarea
              axiosInstance={instance}
              fieldName={fieldNameState}
              idPrefix="richtext"
              placeholder={post_theme}
              minHeight={196}
              variant="Post"
              isRequired
              mentionList={[]}
              onBlur={() => {
                validateField(fieldNameContent);
              }}
              value={JSON.stringify(value?.toJSON())}
              onChangeHandler={(state, html) => {
                setUrlData(findCustomURL(state.toJSON().root));

                if (!html || html === RICHTEXT_INITIAL_VALUE) {
                  setFieldValue(fieldNameState, null);
                  setFieldValue(fieldNameContent, "");
                } else {
                  setFieldValue(fieldNameState, state);
                  setFieldValue(fieldNameContent, html);
                  setTimeout(() => {
                    validateField(fieldNameContent);
                  }, 0);
                }
              }}
            />
          );
        }}
      </Field>

      {sliderOpen && <CreateSlider />}
      {urlData && isPreviewShow && (
        <UrlPreview setIsPreviewShow={setIsPreviewShow} />
      )}
      {pollOpen && <CreatePoll />}
      <ImageUpload />
    </ModalContentContainer>
  );
};

export default ModalContent;

const ModalContentContainer = styled.div`
  max-height: calc(100vh - 277px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing["spacing-4"].value}px;
  padding: ${({ theme }) => theme.spacing["spacing-2"].value}px
    ${({ theme }) => theme.spacing["spacing-4"].value}px;

  ${hideScrollBar}
`;
