import { useContext } from "react";
import styled, { useTheme } from "styled-components";
import { Components } from "@thisgorilla/primate-ds";

import Choices from "./Choices";
import Duration from "./Duration";
import { CreatePostModalContext } from "../..";
import { CHOICE_COUNT_INITIAL_VALUE } from "../../utils";
import { Divider } from "../../../../../../../../commonStyles";
import Input from "../../../../../../../Input";

const CreatePoll = () => {
  const { typography } = useTheme();
  const { choiceCount, setPollOpen, formik, setChoiceCount } = useContext(
    CreatePostModalContext
  );
  const { setFieldValue, setFieldError } = formik;

  return (
    <PollContainer id="create-poll">
      <ButtonContainer>
        <Components.IconButton
          disabled={false}
          iconType="X"
          kind="Secondary"
          size="Small"
          onClick={() => {
            setPollOpen(false);
            const pollFields = [
              ...choiceCount.map((field) => `choice_${field}`),
              "duration",
              "question",
            ];
            pollFields.forEach((field) => {
              setFieldValue(field, "");
              setFieldError(field, undefined);
            });

            setChoiceCount(CHOICE_COUNT_INITIAL_VALUE);
          }}
        />
      </ButtonContainer>
      <Divider />

      <LabelContainer>
        <Components.Text
          style={{ tokenStyle: typography.Heading.XXS }}
          text="Question"
          fontWeight="font-weight-semibold"
          lineHeight="line-height-50"
        />
      </LabelContainer>
      <Input
        fieldName="question"
        idPrefix="create-poll"
        isRequired
        placeholder="Add your question"
        size="Medium"
      />
      <Divider />
      <ChoicesAndDurationContainer $column={choiceCount.length > 5}>
        <Choices />
        <Divider $vertical={choiceCount.length < 6} />
        <Duration />
      </ChoicesAndDurationContainer>
      <Divider />
    </PollContainer>
  );
};

export default CreatePoll;

const PollContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  padding: ${({ theme }) => theme.spacing["spacing-2"].value}px
    ${({ theme }) => theme.spacing["spacing-2"].value}px
    ${({ theme }) => theme.spacing["spacing-6"].value}px;
  border-radius: ${({ theme }) => theme.radius["radius-xs"].value}px;
  border: 1px solid
    ${({ theme }) => theme.Color.border["ui-border-neutral"].value};
  box-shadow: ${({ theme }) => theme.boxShadows["box-shadow-s"]};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
`;
const LabelContainer = styled.div`
  padding: ${({ theme }) => theme.spacing["spacing-1"].value}px 0;
`;

const ChoicesAndDurationContainer = styled.div<{ $column: boolean }>`
  display: flex;
  flex-direction: ${({ $column }) => ($column ? "column" : "row")};
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
`;
