import { useState, useEffect } from "react";

export const useScroll = (id?: string) => {
  const element = id ? document.getElementById(id) : document.documentElement;
  const [scroll, setScroll] = useState({ top: 0, left: 0 });

  useEffect(() => {
    const handleScroll = () => {
      if (!element) return;
      const { scrollTop, scrollLeft } = element;
      setScroll({ left: scrollLeft, top: scrollTop });
    };
    if (!element) return;
    if (id) element.addEventListener("scroll", handleScroll);
    else document.addEventListener("scroll", handleScroll);

    return () => {
      if (id) element.removeEventListener("scroll", handleScroll);
      else document.removeEventListener("scroll", handleScroll);
    };
  }, [element,id]);

  return scroll;
};
