import styled from "styled-components";

import { ModalProps } from "./Modal";

const Modal = ({ modalOpen, children, onDrop }: ModalProps.IProps) => {
  return (
    <ModalOverlay
      $modalopen={modalOpen}
      onDrop={onDrop && onDrop}
      onDragOver={onDrop && ((e) => e.preventDefault())}
    >
      <Container $modalopen={modalOpen}>
        <ModalContainer>{children}</ModalContainer>
      </Container>
    </ModalOverlay>
  );
};

export default Modal;

const ModalOverlay = styled.div<{ $modalopen: boolean }>`
  position: fixed;
  inset: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: ${({ $modalopen }) => ($modalopen ? "visible" : "hidden")};
  transition: ${({ $modalopen }) =>
    $modalopen ? "visibility 0ms ease-out" : "visibility 0ms 300ms ease-out"};
  background: ${({ theme }) =>
    theme.Color.elevation.surface["surface-primary-hover"].value};
`;
const Container = styled.div<{ $modalopen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  transform: translateY(${({ $modalopen }) => ($modalopen ? "0" : "100%")});
  transition: transform 300ms ease-out;
`;
const ModalContainer = styled.div`
  max-width: 726px;
  width: 100%;
  border-radius: ${({ theme }) => theme.radius["radius-xs"].value}px;
  border: 1px solid
    ${({ theme }) => theme.Color.background.inverse.default.value};
  background: ${({ theme }) =>
    theme.Color.elevation.surface["surface-primary"].value};
`;
