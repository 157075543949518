import { SetStateAction, Dispatch } from "react";

import { handleError } from "../errors";
import store, { actions } from "../../redux";
import { API_INSTANCES } from "../api_instance";
import { PostsProps } from "../../redux/slices/posts/Posts";

const dispatch = store.dispatch;

const createPostDraft = () => {
  return API_INSTANCES.POSTS.createPostDraft()
    .then((response) => {
      const data = response.data as PostsProps.createPostDraftResponse;
      dispatch(actions.posts.setCreatePostDraftResponse(data));
    })
    .catch((error) => handleError(error, createPostDraft.name));
};

const activateAPost = (
  data: PostsProps.activateAPostPayload,
  resetPostState: () => void
) => {
  return API_INSTANCES.POSTS.activateAPost(data)
    .then((response) => {
      const data = response.data as PostsProps.activateAPostResponse;
      dispatch(actions.posts.setActivateAPostResponse(data));
      resetPostState();
    })
    .catch((error) => handleError(error, activateAPost.name));
};

const repostAPost = (data: PostsProps.repostAPostPayload) =>
  API_INSTANCES.POSTS.repostAPost(data)
    .then()
    .catch((error) => handleError(error, repostAPost.name));

const getSinglePost = (data: PostsProps.getSinglePostPayload) =>
  API_INSTANCES.POSTS.getSinglePost(data)
    .then()
    .catch((error) => handleError(error, getSinglePost.name));

const uploadsImageForAPost = (
  data: PostsProps.uploadsImageForAPostPayloadForCall,
  setImageUploadOpen: Dispatch<SetStateAction<boolean>>,
  setIsMoreImgUploadButtonDisable: Dispatch<SetStateAction<boolean>>,
  setIsImageUploading?: Dispatch<SetStateAction<boolean>>
) => {
  setIsMoreImgUploadButtonDisable(true);
  setIsImageUploading && setIsImageUploading(true);
  const promiseList = data.images.map((image) =>
    API_INSTANCES.POSTS.uploadsImageForAPost({
      post_id: data.post_id,
      image: image.file,
    })
      .then((response) => {
        const data = response.data as PostsProps.uploadsImageForAPostResponse;
        dispatch(actions.posts.setUploadsImageForAPostResponse(data));
        dispatch(
          actions.posts.setUploadedFile({
            image: {
              ...image,
              id: data[data.length - 1].id,
              status: "fulfilled",
            },
            fileId: image.id,
          })
        );
      })
      .catch(() => {
        dispatch(
          actions.posts.setUploadedFile({
            image: { ...image, status: "rejected" },
            fileId: image.id,
          })
        );
      })
      .finally(() => setImageUploadOpen(false))
  );
  Promise.allSettled(promiseList).then(() => {
    setIsMoreImgUploadButtonDisable(false);
    setIsImageUploading && setIsImageUploading(false);
  });
};

const deleteAnImageForAPost = (data: PostsProps.deleteAnImageForAPostPayload) =>
  API_INSTANCES.POSTS.deleteAnImageForAPost(data)
    .then((response) => {
      const imageData = (response.data ||
        []) as PostsProps.uploadsImageForAPostResponse;

      dispatch(actions.posts.setUploadsImageForAPostResponse(imageData));
      dispatch(actions.posts.setDeleteUploadedFile(data.image_id));
    })
    .catch((error) => handleError(error, deleteAnImageForAPost.name));

const deleteAllImagesForAPost = (
  data: PostsProps.deleteAllImagesForAPostPayload
) =>
  API_INSTANCES.POSTS.deleteAllImagesForAPost(data)
    .then(() => {
      dispatch(actions.posts.setUploadsImageForAPostResponse([]));
      dispatch(actions.posts.setDeleteAllUploadedFile());
    })
    .catch((error) => handleError(error, deleteAnImageForAPost.name));

const getUserPosts = (data: PostsProps.getUserPostsPayload) =>
  API_INSTANCES.POSTS.getUserPosts(data)
    .then()
    .catch((error) => handleError(error, getUserPosts.name));

export const POSTS = {
  createPostDraft,
  activateAPost,
  repostAPost,
  getSinglePost,
  uploadsImageForAPost,
  deleteAnImageForAPost,
  deleteAllImagesForAPost,
  getUserPosts,
};
