import styled, { useTheme } from "styled-components";
import { useEffect, useRef, useState } from "react";
import { Components, Inputs } from "@thisgorilla/primate-ds";

import { CreateSliderProps } from "./CreateSlider";
import { Emoji } from "../../../../../../../Emoji/EmojiIcon";
import { useScroll } from "../../../../../../../../hooks/useScroll";
import { useBodySize } from "../../../../../../../../hooks/useBodySize";
import { useClickOutside } from "../../../../../../../../hooks/useClickOutside";

const SliderDropdown = ({
  option,
  onValueChange,
  selected,
}: CreateSliderProps.SliderDropdown) => {
  const size = useBodySize();
  const dropdownRef = useRef(null);
  const containerRef = useRef(null);
  const { typography } = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const scroll = useScroll("create-post-modal-content");
  const [menuPosition, setMenuPosition] = useState({
    top: 0,
    left: 0,
    width: 0,
  });

  useClickOutside({
    ref: dropdownRef,
    setState: setIsOpen,
    excludedRef: containerRef,
  });

  useEffect(() => {
    if (containerRef?.current) {
      const wrapperRect = (
        containerRef?.current as Element
      )?.getBoundingClientRect();

      setMenuPosition({
        top: wrapperRect?.bottom,
        left: wrapperRect?.left,
        width: wrapperRect?.width,
      });
    }
  }, [isOpen, containerRef, size, scroll]);

  return (
    <>
      <Container
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        $active={isOpen}
        ref={containerRef}
      >
        <EmojiContainer>
          <Emoji type={selected} />
        </EmojiContainer>
        <div>
          <Components.IconButton
            disabled={false}
            iconType={isOpen ? "ChevronUp" : "ChevronDown"}
            kind="Tertiary"
            onClick={() => {}}
            size="Small"
          />
        </div>
      </Container>
      {isOpen && (
        <MenuContainer ref={dropdownRef} $menuPosition={menuPosition}>
          <Components.Menu size="fill" itemGap={16}>
            <Components.Text
              style={{ tokenStyle: typography.Heading.S }}
              fontWeight="font-weight-semibold"
              text="Available emojis"
              lineHeight="line-height-400"
            />
            <EmojiListWrapper>
              {option.map(({ id, data }, index) => (
                <EmojiWrapper
                  $selected={data === selected}
                  key={index}
                  onClick={() => {
                    onValueChange(id);
                    setIsOpen(false);
                  }}
                >
                  <Emoji type={data} />
                </EmojiWrapper>
              ))}
            </EmojiListWrapper>
          </Components.Menu>
        </MenuContainer>
      )}
    </>
  );
};

export default SliderDropdown;

const Container = styled.div<{
  $active: boolean;
}>`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  padding: ${({ theme }) => theme.spacing["spacing-2"].value}px
    ${({ theme }) => theme.spacing["spacing-2"].value}px
    ${({ theme }) => theme.spacing["spacing-2"].value}px
    ${({ theme }) => theme.spacing["spacing-4"].value}px;
  border-radius: ${({ theme }) => theme.radius["radius-xxs"].value}px;
  ${({ $active }) =>
    $active
      ? Inputs.CommonField.CommonFieldStyle.inputActivecss
      : Inputs.CommonField.getInputCss("Normal")}
`;

const EmojiContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const EmojiListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
`;
const EmojiWrapper = styled.div<{ $selected: boolean }>`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.radius["radius-xxs"].value}px;
  cursor: pointer;
  background: ${({ theme, $selected }) =>
    $selected
      ? theme.Color.elevation.surface["surface-primary-hover"].value
      : "transparent"};
  &:hover {
    background: ${({ theme }) =>
      theme.Color.elevation.surface["surface-primary-hover"].value};
  }
`;

const MenuContainer = styled.div<{
  $menuPosition: { top: number; left: number; width: number };
}>`
  position: fixed;
  top: ${({ $menuPosition }) => $menuPosition.top + 8}px;
  left: ${({ $menuPosition }) => $menuPosition.left + 1}px;
  z-index: 10;
  width: ${({ $menuPosition }) => $menuPosition.width - 2}px;
  border-radius: ${({ theme }) => theme.radius["radius-xs"].value}px;
  background: ${({ theme }) =>
    theme.Color.elevation.surface["surface-primary"].value};

  & > div {
    padding: ${({ theme }) => theme.spacing["spacing-5"].value}px
      ${({ theme }) => theme.spacing["spacing-3"].value}px;
  }
`;
