import { ThumbsUp } from "./ThumbsUp";
import { Heart } from "./Heart";
import { Clap } from "./Clap";
import { Star } from "./Star";
import { Party } from "./Party";
import { Fire } from "./Fire";
import { Rocket } from "./Rocket";
import { Hundred } from "./Hundred";
import { Muscle } from "./Muscle";
import { ThumbsDown } from "./ThumbsDown";
import { Angry } from "./Angry";
import { Cry } from "./Cry";
import { BrokenHeart } from "./BrokenHeart";
import { Disappointed } from "./Disappointed";
import { Confused } from "./Confused";
import { Weary } from "./Weary";
import { Tired } from "./Tired";
import { Scream } from "./Scream";
import { Sad } from "./Sad";
import { Smile } from "./Smile";

export const Emojis = {
  ThumbsUp,
  Heart,
  Clap,
  Star,
  Party,
  Fire,
  Rocket,
  Hundred,
  Muscle,
  ThumbsDown,
  Angry,
  Cry,
  BrokenHeart,
  Disappointed,
  Confused,
  Weary,
  Tired,
  Scream,
  Sad,
  Smile,
};

export type { EmojiProps } from "./Emoji";
