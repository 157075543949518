import { Formik, FormikValues } from "formik";
import { createContext, useEffect, useState } from "react";

import ModalHeader from "./ModalHeader";
import ModalFooter from "./ModalFooter";
import ModalContent from "./ModalContent";
import { enableScroll } from "../../../../../../utils";
import { CreatePostModalProps } from "./CreatePostModal";
import { API_CALLS } from "../../../../../../API/api_calls";
import { CHOICE_COUNT_INITIAL_VALUE, createPostValidation } from "./utils";
import {
  actions,
  useAppDispatch,
  useAppSelector,
} from "../../../../../../redux";

export const CreatePostModalContext = createContext(
  {} as CreatePostModalProps.Context
);

const CreatePostModal = ({
  setCreatePostOpen,
  pollOpen,
  setPollOpen,
  imageUploadOpen,
  setImageUploadOpen,
  isMoreImgUploadButtonDisable,
  setIsMoreImgUploadButtonDisable,
  setSliderOpen,
  sliderOpen,
}: CreatePostModalProps.IProps) => {
  const dispatch = useAppDispatch();
  const { post_id } = useAppSelector((state) => state.posts);
  const [choiceCount, setChoiceCount] = useState(CHOICE_COUNT_INITIAL_VALUE);
  const [urlData, setUrlData] = useState<CreatePostModalProps.CustomURL | null>(
    null
  );

  useEffect(() => {
    API_CALLS.POSTS.createPostDraft();
  }, []);

  const resetPostState = () => {
    dispatch(actions.posts.reset());
    setCreatePostOpen(false);
    setImageUploadOpen(false);
    setPollOpen(false);
    setSliderOpen(false);
    enableScroll();
  };

  const postSubmit = (values: FormikValues) => {
    const newValues = { ...values };
    delete newValues.duration;
    delete newValues.content;
    delete newValues.editorState;
    delete newValues.question;
    delete newValues.slider_question;
    delete newValues.right_emoji_id;
    delete newValues.left_emoji_id;
    const newChoice: FormikValues = {};

    Object.values(newValues).forEach((choice, index) => {
      newChoice[`choice_${index + 1}`] = choice;
    });

    API_CALLS.POSTS.activateAPost(
      {
        content: values.content || null,
        delivery_date: new Date().toISOString(),
        node_tree: JSON.stringify(values.editorState?.state?.toJSON()),
        post_id,
        topics: [],
        url_id: urlData?.id ?? null,
        poll: pollOpen
          ? {
              ...newChoice,
              post_id,
              question: values.question,
              duration: values.duration,
            }
          : null,
        slider: sliderOpen
          ? {
              left_emoji_id: values.left_emoji_id,
              post_id,
              question: values.slider_question,
              right_emoji_id: values.right_emoji_id,
            }
          : null,
      },
      resetPostState
    );
  };

  return (
    <Formik
      initialValues={{}}
      validationSchema={createPostValidation({
        choiceCount,
        pollOpen,
        sliderOpen,
      })}
      onSubmit={postSubmit}
      validateOnChange={false}
      isInitialValid={false}
      validateOnBlur
    >
      {(formik) => {
        const contextValue: CreatePostModalProps.Context = {
          setCreatePostOpen,
          urlData,
          setUrlData,
          pollOpen,
          setPollOpen,
          choiceCount,
          setChoiceCount,
          formik,
          setImageUploadOpen,
          imageUploadOpen,
          isMoreImgUploadButtonDisable,
          setIsMoreImgUploadButtonDisable,
          setSliderOpen,
          sliderOpen,
          resetPostState,
        };

        return (
          <CreatePostModalContext.Provider value={contextValue}>
            <ModalHeader />
            <ModalContent />
            <ModalFooter />
          </CreatePostModalContext.Provider>
        );
      }}
    </Formik>
  );
};

export default CreatePostModal;
