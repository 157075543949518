import { FormikValues } from "formik";
import { KeyboardEvent, useContext } from "react";
import styled, { useTheme } from "styled-components";
import { Components } from "@thisgorilla/primate-ds";

import Input from "../../../../../../../Input";
import { CreatePostModalContext } from "../..";

const Choices = () => {
  const { typography } = useTheme();
  const { choiceCount, setChoiceCount, formik } = useContext(
    CreatePostModalContext
  );

  const deleteField = (fieldName: string, choiceNumber: number) => {
    if (choiceCount.length > 3) {
      formik.setValues((prevValues: FormikValues) => {
        const newValues = { ...prevValues };
        delete newValues[fieldName];
        return newValues;
      });
      formik.setFieldError(fieldName, undefined);
      setChoiceCount(choiceCount.filter((count) => count !== choiceNumber));

      setTimeout(() => {
        const errorOption = Object.keys(formik.errors).filter(
          (errorKey) => errorKey.includes("choice_") && errorKey !== fieldName
        );
        errorOption.map((optionError) => formik.validateField(optionError));
      }, 0);
    }
  };

  const onOptionKeyDown = (
    e: KeyboardEvent<HTMLInputElement>,
    choiceNumber: number
  ) => {
    const lastOption = choiceCount[choiceCount.length - 1];
    if (e.key === "Enter") {
      if (lastOption === choiceNumber && choiceCount.length < 10) {
        setChoiceCount([
          ...choiceCount,
          choiceCount[choiceCount.length - 1] + 1,
        ]);
      }
      setTimeout(() => {
        document.getElementById(`choice_choice_${choiceNumber + 1}`)?.focus();
      }, 0);
    }
  };
  return (
    <ChoicesContainer>
      <ChoicesHeading>
        <Components.Text
          style={{ tokenStyle: typography.Heading.XXS }}
          text="Poll Options"
          fontWeight="font-weight-semibold"
          lineHeight={"line-height-50"}
        />
        <Components.IconButton
          iconType="Plus"
          disabled={choiceCount.length === 10}
          kind="Secondary"
          onClick={() =>
            setChoiceCount([
              ...choiceCount,
              choiceCount[choiceCount.length - 1] + 1,
            ])
          }
          size="XS"
        />
      </ChoicesHeading>
      <Container>
        <ChoicesContainer>
          {choiceCount.slice(0, 5).map((choiceNumber) => (
            <Input
              key={choiceNumber}
              fieldName={`choice_${choiceNumber}`}
              idPrefix="choice"
              isRequired
              placeholder={`Option ${choiceCount.indexOf(choiceNumber) + 1}`}
              onKeyDown={(e) => onOptionKeyDown(e, choiceNumber)}
              iconClickhandler={() =>
                deleteField(`choice_${choiceNumber}`, choiceNumber)
              }
              iconType="X"
            />
          ))}
        </ChoicesContainer>
        {choiceCount.slice(5).length > 0 && (
          <ChoicesContainer>
            {choiceCount.slice(5).map((choiceNumber) => (
              <Input
                key={choiceNumber}
                fieldName={`choice_${choiceNumber}`}
                idPrefix="choice"
                isRequired
                placeholder={`Option ${choiceCount.indexOf(choiceNumber) + 1}`}
                onKeyDown={(e) => onOptionKeyDown(e, choiceNumber)}
                iconClickhandler={() =>
                  deleteField(`choice_${choiceNumber}`, choiceNumber)
                }
                iconType="X"
              />
            ))}
          </ChoicesContainer>
        )}
      </Container>
    </ChoicesContainer>
  );
};

export default Choices;

const ChoicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
`;
const Container = styled.div`
  display: flex;
  width: 100%;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
`;

const ChoicesHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
`;
