import { useContext, useEffect } from "react";
import styled, { useTheme } from "styled-components";
import { Components } from "@thisgorilla/primate-ds";

import SliderPreview from "./SliderPreview";
import SliderDropdown from "./SliderDropdown";
import Input from "../../../../../../../Input";
import { CreatePostModalContext } from "../..";
import { Divider } from "../../../../../../../../commonStyles";
import { useAppSelector } from "../../../../../../../../redux";

const CreateSlider = () => {
  const { typography } = useTheme();
  const { emojis: emojiList } = useAppSelector((state) => state.me);
  const { setSliderOpen, formik } = useContext(CreatePostModalContext);
  const { values, setFieldValue } = formik;
  const { right_emoji_id, left_emoji_id, slider_question } = values;

  useEffect(() => {
    if (!left_emoji_id && !right_emoji_id) {
      setFieldValue("left_emoji_id", emojiList[emojiList.length - 2].id);
      setFieldValue("right_emoji_id", emojiList[emojiList.length - 1].id);
    }
  }, [emojiList, left_emoji_id, right_emoji_id, setFieldValue]);

  return (
    <CreateSlidetContainer>
      <ButtonContainer>
        <Components.IconButton
          disabled={false}
          iconType="X"
          kind="Secondary"
          size="Small"
          onClick={() => {
            setSliderOpen(false);
            formik.setFieldValue("slider_question", "");
            formik.setFieldError("slider_question", undefined);
          }}
        />
      </ButtonContainer>
      <Divider />
      <Input
        fieldName="slider_question"
        idPrefix="create-slider"
        isRequired
        placeholder="Ask your question"
        label="Question"
        size="Large"
        maxCount={140}
        helpText={`${
          formik?.values?.slider_question?.length ?? 0
        }/140 characters`}
        helpTextKind={
          formik?.values?.slider_question?.length ? "Positive" : "Normal"
        }
      />
      <Divider />
      <Components.Text
        text="Add rating options"
        style={{ tokenStyle: typography.Body.M }}
      />
      <SliderOptionContainer>
        <SliderDropdown
          option={emojiList.filter((emoji) => emoji.id !== right_emoji_id)}
          onValueChange={(value) => setFieldValue("left_emoji_id", value)}
          selected={
            emojiList.find((emoji) => emoji.id === left_emoji_id)?.data ??
            "none"
          }
        />
        <SliderDropdown
          option={emojiList.filter((emoji) => emoji.id !== left_emoji_id)}
          onValueChange={(value) => setFieldValue("right_emoji_id", value)}
          selected={
            emojiList.find((emoji) => emoji.id === right_emoji_id)?.data ??
            "none"
          }
        />
      </SliderOptionContainer>
      {slider_question?.length > 0 && (
        <SliderPreview
          emojiValue={[
            emojiList.find((emoji) => emoji.id === left_emoji_id)?.data ??
              "none",
            emojiList.find((emoji) => emoji.id === right_emoji_id)?.data ??
              "none",
          ]}
        />
      )}
    </CreateSlidetContainer>
  );
};

export default CreateSlider;
const SliderOptionContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing["spacing-4"].value}px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
`;

const CreateSlidetContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  padding: ${({ theme }) => theme.spacing["spacing-2"].value}px
    ${({ theme }) => theme.spacing["spacing-2"].value}px
    ${({ theme }) => theme.spacing["spacing-6"].value}px;
  border-radius: ${({ theme }) => theme.radius["radius-xs"].value}px;
  border: 1px solid
    ${({ theme }) => theme.Color.border["ui-border-neutral"].value};
  box-shadow: ${({ theme }) => theme.boxShadows["box-shadow-s"]};
`;
