import { DefaultPost } from "./DefaultPost";
import { CenterPanelStyles } from "./CenterPanel.styled";

export const CenterPanel = () => {
  return (
    <CenterPanelStyles.Container>
      <DefaultPost />
    </CenterPanelStyles.Container>
  );
};
