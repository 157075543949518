import { useContext } from "react";
import styled from "styled-components";
import { Components } from "@thisgorilla/primate-ds";

import ImgPreview from "./ImgPreview";
import { CreatePostModalContext } from "../..";
import { ImageUploadProps } from "./ImageUpload";
import { MAX_IMAGE_UPLOAD_COUNT } from "./utils";
import { useAppSelector } from "../../../../../../../../redux";
import { API_CALLS } from "../../../../../../../../API/api_calls";

const MultiImagePreview = ({ fileInputRef }: ImageUploadProps.IProps) => {
  const { uploadedFiles, post_id } = useAppSelector((state) => state.posts);
  const { isMoreImgUploadButtonDisable } = useContext(CreatePostModalContext);

  return (
    <MultiImagePreviewContainer>
      <Container>
        {uploadedFiles.map((image, index) => (
          <ImgPreview key={index} {...image} />
        ))}

        <AddImage $disabled={ isMoreImgUploadButtonDisable ||
              uploadedFiles.length === MAX_IMAGE_UPLOAD_COUNT}>
          <Components.IconButton
            iconType="Plus"
            onClick={() => fileInputRef?.current?.click()}
            disabled={
              isMoreImgUploadButtonDisable ||
              uploadedFiles.length === MAX_IMAGE_UPLOAD_COUNT
            }
            kind="Secondary"
            size="Large"
          />
        </AddImage>
      </Container>
      <Components.Button
        color="On-Primary"
        size="Small"
        label="Delete all"
        kind="Primary"
        disabled={false}
        onClick={() => {
          API_CALLS.POSTS.deleteAllImagesForAPost({ post_id });
        }}
        iconType="Trash2"
        iconPosition="left"
      />
    </MultiImagePreviewContainer>
  );
};

export default MultiImagePreview;

const MultiImagePreviewContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  padding: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  border-radius: ${({ theme }) => theme.radius["radius-m"].value}px;
  box-shadow: none;
  border: none;
  background: ${({ theme }) =>
    theme.Color.elevation.surface["surface-secondary"].value};

  & > div > button {
    display: none;
  }

  &:hover {
    background: ${({ theme }) =>
      theme.Color.elevation.surface["surface-primary-hover"].value};

    & > div > button {
      display: flex;
    }
  }
`;

const Container = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  flex-wrap: wrap;
`;

const AddImage = styled.div<{ $disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 70px;
  height: 70px;
  padding: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  border-radius: ${({ theme }) => theme.radius["radius-xs"].value}px;
  background: ${({ theme, $disabled }) =>
    $disabled
      ? theme.Color.background.system["background-disabled"].value
      : theme.Color.background.brand.subtlest["default-hover"].value};
  cursor: pointer;
`;
